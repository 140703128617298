import { makeStyles, Typography } from '@material-ui/core'
import { CustomDivider } from '../common/CustomDivider'

const useStyles = makeStyles((theme) => ({
    header: {
        textAlign: 'center',
    }
}));

interface Props {
    title: string;
}

export const SectionHeader: React.FC<Props> = ({ title }) => {
    const classes = useStyles();

    return (
        <div>
            <Typography variant="h5" className={classes.header} >
                {title}
            </Typography>
            <CustomDivider selectedWidth={200} />
        </div>
    )
}

import { AccordionDetails, AccordionSummary, Checkbox, FormControlLabel, FormGroup, makeStyles, Typography, withStyles } from "@material-ui/core"
import MuiAccordion from '@material-ui/core/Accordion';
import { Feature, FeatureCollection } from "geojson"
import { useRecoilState } from "recoil";
import { mapFeatures } from "../../state/map";
import { editFeatureCollection, geoJsonColorMapping } from "../../utils/map"
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

const useStyles = makeStyles((theme) => ({
    root: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'left',
    }
  }));
  
const Accordion = withStyles({
  root: {
    border: '1px solid rgba(0, 0, 0, .125)',
    '&$expanded': {
      margin: 0,
    },
  },
  expanded: {},
})(MuiAccordion);

interface Props {
}

export const MapToggleButtons = (props: Props) => {

    const classes = useStyles();
    const [currentMapFeatures, setCurrentMapFeatures] = useRecoilState<FeatureCollection[] | null>(mapFeatures);
    
    const enableOrDisableFeatureOnMap = (clickedFeature: Feature) => {
      if(currentMapFeatures){
        setCurrentMapFeatures(editFeatureCollection(currentMapFeatures, clickedFeature));
      }
    }
    
    return (
      <div className={classes.root}>
        <FormGroup>
          {currentMapFeatures?.map((fc) => 
            fc.features.map((feature : Feature) => {
              if(feature?.properties?.has_geometry === true){
                return <Accordion key={feature?.properties?.Beskrivelse+feature.properties?.index}>
                  <AccordionSummary
                    expandIcon={<ArrowDropDownIcon />}
                    aria-label="Expand"
                    aria-controls="additional-actions1-content"
                    id="additional-actions1-header"
                  >
                      <FormControlLabel
                      key={feature?.properties?.Beskrivelse+feature.properties?.index}
                      control={<Checkbox checked={feature?.properties?.show_on_map} style={geoJsonColorMapping(feature)} onChange={enableOrDisableFeatureOnMap.bind(this, feature)}/>}
                      name={feature?.properties?.tittel}
                      label={feature?.properties?.tittel}
                      labelPlacement="end"
                    />
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      {feature?.properties?.Beskrivelse}
                    </Typography>
                  </AccordionDetails>
                </Accordion>
                
              }
            } 
          ) 
        )}
        </FormGroup>
      </div>
    )
}

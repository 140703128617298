import { CircularProgress, Container } from "@material-ui/core";
import { Suspense, useRef } from "react";
import { Header } from "./header/Header";
import { Content } from "./content/Content";
import { AnalysisResult } from "./AnalysisResult";
import { Search } from "./search/Search";
import { Logos } from "./header/Logos";
import { Questionnaire } from "./questionnaire/Questionnaire";

export const MainWrapper = () => {

    const analysisInfoRef = useRef<HTMLDivElement>(null);
    const opportunitiesAndLimitsRef = useRef<HTMLDivElement>(null);
    const designRef = useRef<HTMLDivElement>(null);
    const buildingsRef = useRef<HTMLDivElement>(null);
    const keyInfoRef = useRef<HTMLDivElement>(null);
    const documentsRef = useRef<HTMLDivElement>(null);
    const mapRef = useRef<HTMLDivElement>(null);

    return (
        <Container maxWidth="lg">
            <Content
                analysisInfoRef={analysisInfoRef}
                opportunitiesAndLimitsRef={opportunitiesAndLimitsRef}
                designRef={designRef}
                buildingsInfoRef={buildingsRef}
                keyInfoRef={keyInfoRef}
                documentsRef={documentsRef}
                mapRef={mapRef}
            >
                <Logos />
                <Header />

                <Search mapRef={mapRef}/>
                <Suspense fallback={<CircularProgress color="secondary" />}>
                    <AnalysisResult
                        analysisInfoRef={analysisInfoRef}
                        opportunitiesAndLimitsRef={opportunitiesAndLimitsRef}
                        designRef={designRef}
                        buildingsRef={buildingsRef}
                        keyInfoRef={keyInfoRef}
                        documentsRef={documentsRef}
                    />
                </Suspense>
                <br/>
                <Questionnaire/>
                <br/>
            </Content>
        </Container>
    )
}

import { Button, makeStyles } from "@material-ui/core"
import { useRecoilValue } from "recoil"
import { Eiendom, selectedEiendom } from "../../state/plan";
import { openInNewTab } from "../../utils/utils";

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex", 
        flexDirection: "column",
        [theme.breakpoints.down('md')]: {
            flexDirection: 'row',
            justifyContent: 'space-evenly',
            padding: theme.spacing(2)
          },
    }
}));

export const ProperyInfo = () => {
    const eiendom = useRecoilValue<Eiendom | 'nohit' |null>(selectedEiendom);
    const classes = useStyles();

    return (
        <div>
            {eiendom && eiendom !== 'nohit' &&
                <div className={classes.root}>
                        {eiendom.Eiendomsinformasjon.WmsUrl &&
                            <Button variant='contained' color='primary' onClick={() => openInNewTab(eiendom.Eiendomsinformasjon.WmsUrl)}>
                                Åpne plankart: {eiendom?.Eiendomsinformasjon?.Plannavn}
                            </Button>
                        }
                </div>
            }
        </div>
    )
}

import { makeStyles, Typography } from "@material-ui/core";
import { useRecoilValue } from "recoil"
import { formaalCount as formaalCountState } from "../../state/plan"

const useStyles = makeStyles((theme) => ({
    root: {
        textAlign: 'center',
        marginBottom: theme.spacing(2)
    },
}));

export const AnalysisResultThresholdInfo = () => {
    const formaalCount = useRecoilValue<number| null>(formaalCountState);
    const classes = useStyles();

    return (
        <div className={classes.root}>
            {formaalCount && formaalCount > 3 && <Typography>Valgt eiendom er inndelt i <span style={{fontWeight: 'bold'}}>{formaalCount} eiendomsflater</span>, her vises kun de <span style={{fontWeight: 'bold'}}>tre</span> største. For mer informasjon, ta kontakt med din kommune</Typography>}
        </div>
    )
}

import L from 'leaflet';
import { SelectedStreetAddress } from './fritekstsoek';
import { onMapClick } from '../utils/map';
import { FeatureCollection } from 'geojson';
import { atom } from 'recoil';

const apiKey = process.env.REACT_APP_API_KEY
const currentYear = new Date().getFullYear();

export const InitMap = (selectedSearch: SelectedStreetAddress) : L.Map | null => {
    const selectedCenter = {
      lng: selectedSearch.latLng.lat, 
      lat: selectedSearch.latLng.lng
    }
    const map = L.map('map', {center: selectedCenter, zoom: 16});
    L.tileLayer(`https://waapi.webatlas.no/maptiles/tiles/webatlas-standard-hybrid/wa_grid/{z}/{x}/{y}.jpeg?APITOKEN=${apiKey}`, {
        attribution: `&copy; ${currentYear} Norkart AS`
    }).addTo(map);
    map.on('click', onMapClick);
    map.scrollWheelZoom.disable();
    map.dragging.disable();
    return map;
  }

export const mapFeatures = atom<FeatureCollection[] | null>({
  key: "mapFeatures",
  default: null
})
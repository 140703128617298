import { Feature, FeatureCollection, Geometry } from 'geojson';
import { selector } from 'recoil';
import { userSelectedStreetAddress } from './fritekstsoek';
import { getBestemmelsesUrl, getEiendom } from '../utils/blob';
import { generateFeatureCollectionFromFeatureArray } from '../utils/map';
import turfArea from '@turf/area';

export interface Eiendom {
    Eiendomsinformasjon: Eiendomsinformasjon;
    Tittel:              string;
    Beskrivelse:         string;
    Formaal:             Formaal[];
}

export interface Eiendomsinformasjon {
    Plannavn:       string;
    Mid:            string;
    Planid:         string;
    Gårdsnummer:    string;
    Bruksnummer:    string;
    Festenummer:    string;
    Seksjonsnummer: string;
    WmsUrl:         string;
    Geometri:       null | Geometry;
    ByggeforbudGeometri:       null | Geometry;
}

export interface Formaal {
    Tittel:      string;
    Beskrivelse: string;
    Geometri?:    null | Geometry;
    Egenskaper:  Egenskaper[];
}

export interface Egenskaper {
    Key:         string;
    Value:        string;
    Explanation?: string;
    StaticLegendLabel?: string;
}

export const formallist = ["StorrelserOgArealer", "Hoyder", "Hensynssoner", "BoderOgAnneks", "GarasjeOgParkering", "EstetiskeKrav", "AnnenInfo", "AndreForhold", "Planformaal" ]

export const selectedEiendom = selector<Eiendom | 'nohit' | null>({
    key: 'selectedEiendom',
    get: async ({get}) => {
        const selectedEiendom = get(userSelectedStreetAddress);

        if(selectedEiendom){
            const res = await getEiendom(`${selectedEiendom.matrikkelId}`);
            return res ? res : 'nohit';        
        }
        return null;
    },
});

export const bestemmelsesDocumentState = selector<string | null>({
    key: 'bestemmelsesDocumnetState',
    get: async ({get}) => {
        const eiendom = get(selectedEiendom);
        if (eiendom === null || eiendom ==='nohit') return null;
        return getBestemmelsesUrl(eiendom);        
    },
}); 

export const isHytteplan = selector<boolean>({
    key: 'isHytteplan',
    get: async ({get}) => {
        const eiendom = get(selectedEiendom);
        if (eiendom === null || eiendom ==='nohit'){
            return false;
        } 
        if (eiendom.Eiendomsinformasjon.Planid === "2019004" || eiendom.Eiendomsinformasjon.Planid === "2013003"){
            return true;
        }
        return false;      
    },
}); 

export const mappedFeatureCollection = selector<FeatureCollection[] | null>({
    key: 'mapFeatureCollection',
    get: async ({get}) => {
        const eiendom = get(selectedEiendom);
        if(eiendom && eiendom !== 'nohit'){
            const featureCollectionList = Array<FeatureCollection>();
            featureCollectionList.push(convertOverallInfoToFeatureCollection(eiendom));

            const preList = Array<FeatureCollection>();
                eiendom.Formaal.forEach((currentFormaal : any, index : number) => {
                    const fc = convertFormaalToFeatureCollection(currentFormaal, index)
                    preList.push(fc);
                });
                const filteredArea = preList.sort((fc1, fc2) => fc2.features[fc2.features.length -1].properties?.geomArea - fc1.features[fc1.features.length -1].properties?.geomArea).slice(0,3);
                filteredArea.forEach((fc) => {
                    featureCollectionList.push(fc);
                })
            return featureCollectionList;
        }
        return null;
    },
});

export const formaalCount = selector<number | null>({
    key: 'formaalCount',
    get: async ({get}) => {
        const eiendom = get(selectedEiendom);
        if(eiendom && eiendom !== 'nohit' && eiendom.Formaal.length){
            return eiendom.Formaal.length;
        }
        return null;
    },
});

const convertFormaalToFeatureCollection = (currentFormaal : any, index : number): FeatureCollection => {
    const formaalFeatureList = new Array<Feature>(); 
    formallist.forEach((formaalType) => {
        if(currentFormaal[`${formaalType}`] && formaalIncludesValidProperties(currentFormaal[`${formaalType}`])){
            const thisFormaal = currentFormaal[`${formaalType}`];
            const staticLabel = getStaticLabel(thisFormaal.Egenskaper);
            if(thisFormaal.Geometri){
                formaalFeatureList.push({
                    "type": "Feature",
                    "properties": {
                        "index": index,  
                        "key": formaalType,
                        "tittel": staticLabel,
                        "Beskrivelse": `Eiendomsflate regulert til:  ${staticLabel}. For mer info, se nøkkelinformasjon.`,
                        "show_on_map": false,
                        "has_geometry" : true,
                        "formaal": {
                            "Tittel" : thisFormaal.Tittel,
                            "Beskrivelse" : thisFormaal.Beskrivelse, 
                            "Egenskaper": thisFormaal.Egenskaper
                        },
                        "geomArea": Math.round(turfArea(JSON.parse(`${thisFormaal.Geometri}`)))
                    },
                    "geometry":JSON.parse(`${thisFormaal.Geometri}`)
                })
            }
            else{    
                formaalFeatureList.push({
                    "type": "Feature",
                    "properties": {
                        "index": index,  
                        "key": formaalType,
                        "tittel": staticLabel,
                        "Beskrivelse": `Eiendomsflate regulert til: ${staticLabel}. For mer info, se nøkkelinformasjon.`,
                        "show_on_map": false,
                        "has_geometry": false,
                        "formaal": {
                            "Tittel" : thisFormaal.Tittel,
                            "Beskrivelse" : thisFormaal.Beskrivelse, 
                            "Egenskaper": thisFormaal.Egenskaper
                        },
                        "geomArea": 0
                    },
                    "geometry": {
                        "type": "Polygon",
                        "coordinates": []
                        }
                })
            }
        }
    }) 
    return generateFeatureCollectionFromFeatureArray(formaalFeatureList);
}

const convertOverallInfoToFeatureCollection = (eiendom : Eiendom) : FeatureCollection => {
    const standardFeaturesArray = new Array<Feature>();
    standardFeaturesArray.push({
        "type": "Feature",
        "properties": {
            "key": "Omriss",
            "tittel": "Eiendom",
            "Beskrivelse": `Eiendom: ${eiendom.Eiendomsinformasjon.Gårdsnummer}/${eiendom.Eiendomsinformasjon.Bruksnummer}`,
            "show_on_map": true,
            "has_geometry" : true,
        },
        "geometry": JSON.parse(`${eiendom?.Eiendomsinformasjon.Geometri}`)
    })
    standardFeaturesArray.push({
        "type": "Feature",
        "properties": {
            "key": "Byggeforbud",
            "tittel": "Byggegrense",
            "Beskrivelse": "Inntegnet byggegrense gjelder kun hovedbygningen på eiendommen. Andre typer bygninger, f.eks garasje og anneks, kan ha andre byggegrenser",
            "show_on_map": false,
            "has_geometry" : true,
        },
        "geometry": JSON.parse(`${eiendom?.Eiendomsinformasjon.ByggeforbudGeometri}`)
    })
    return generateFeatureCollectionFromFeatureArray(standardFeaturesArray);
}

const formaalIncludesValidProperties = (formaal : any) : boolean => {
    let hasOneValidProperty = false;
    formaal.Egenskaper.forEach((egenskap : any) => {
        if(egenskap.Value){
            hasOneValidProperty = true;
        }
    });
    return hasOneValidProperty;
}


const getStaticLabel = (egenskaper : any) : string => {
    let staticLabel = "Feltet kan ikke brukes til bolig";
    let staticLabelGenerated = "";
        
    egenskaper.forEach((egenskap : any) => {
        if(egenskap.StaticLegendLabel){
            staticLabel = egenskap.StaticLegendLabel;
        }
        if(egenskap.Key === "Navn på feltet" || egenskap.Key === "Flatenavn" && egenskap.Value){
            staticLabelGenerated += `${egenskap.Value}: `
        }
        if(egenskap.Key === "Feltet kan brukes til" || egenskap.Key === "Formål" ){
            if(egenskap.Value){
                staticLabelGenerated += ` ${egenskap.Value}`
            }else{
                staticLabelGenerated += ` ${staticLabel}`
            }
        }   
    });
    if(staticLabelGenerated !== "" && staticLabel === "Feltet kan ikke brukes til bolig"){
        return staticLabelGenerated
    }
    return staticLabel;
}



export const addressSuggestions = () => {
    return [
        {
            id: "3042010030121000A000", 
            matrikkelId: "30420005701070000000", 
            text: "Svøovegen 121A, Hemsedal", 
            veinavn: "Svøovegen",
            latlng: {
                lat: 8.63426916373639, 
                lng: 60.8401674847359
            }        
        },
        {
            id: "3042010030121000B000", 
            matrikkelId: "30420005701150000000", 
            text: "Svøovegen 121B, Hemsedal", 
            veinavn: "Svøovegen",
            latlng: {
                lat: 8.63483344499769, 
                lng: 60.8405344320575
            }        
        },
        {
            id: "30420100301230000000", 
            matrikkelId: "30420005701080000000", 
            text: "Svøovegen 123, Hemsedal", 
            veinavn: "Svøovegen",
            latlng: {
                lat: 8.6354177213696, 
                lng: 60.8404390530563
            }        
        },
        {
            id: "30430112300010000000", 
            matrikkelId: "30430011102290000000", 
            text: "Øvre Kolbotn 1, Ål", 
            veinavn: "Øvre Kolbotn",
            latlng: {
                lat: 8.55518940963522, 
                lng: 60.6201097595556
            }  
        },
        {
            id: "30430128800100000000", 
            matrikkelId: "30430001500400000000", 
            text: "Bjødnastølen 10, Ål", 
            veinavn: "Bjødnastølen",
            latlng: {
                lat: 8.70836463030571, 
                lng: 60.7178904938041
            }  
        },
        {
            id: "30420005700440000000", 
            matrikkelId: "30420005700440000000", 
            text: "Hemsedal, 57/44", 
            veinavn: "Hemsedal, 57/44",
            latlng: {
                lat: 8.63913914281012, 
                lng: 60.8301824972576
            }  
        }
    ]
}
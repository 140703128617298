import { Typography } from '@material-ui/core'
import { Suspense } from 'react'
import { useRecoilValue } from 'recoil'
import { Eiendom, selectedEiendom } from '../state/plan'
import { Documents } from './documents/Documents'
import { DynamicContent } from './common/DynamicContent'
import { AnalysisResultProvider } from './result/AnalysisResultProvider'
import { AnalysisResultThresholdInfo } from './result/AnalysisResultThresholdInfo'
import { SearchInfo } from './search/SearchInfo'
import { OpportunitiesAndLimits } from './staticinfo/OpportunitiesAndLimits'
import { Design } from './staticinfo/Design'
import { AnalyisInfo } from './staticinfo/AnalysisInfo'
import { BuildingsInfo } from './staticinfo/BuildingsInfo'

interface Props {
    analysisInfoRef: React.MutableRefObject<any>;
    opportunitiesAndLimitsRef: React.MutableRefObject<any>;
    designRef: React.MutableRefObject<any>;
    buildingsRef: React.MutableRefObject<any>;
    keyInfoRef: React.MutableRefObject<any>;
    documentsRef: React.MutableRefObject<any>;
}

export const AnalysisResult = ({analysisInfoRef, opportunitiesAndLimitsRef, designRef, buildingsRef, keyInfoRef, documentsRef}: Props) => {
    const eiendom = useRecoilValue<Eiendom | 'nohit' |  null>(selectedEiendom);
    return (
        <div>
            {eiendom === 'nohit' && <Typography style={{textAlign:'center'}} color="primary">Ingen planer funnet for ditt søk. Denne piloten inneholder kun spesifikke planer for Hemsedal og Ål kommune</Typography>}
            {eiendom && eiendom !== 'nohit' ? 
                <div>
                    <Suspense fallback={<div>Loading...</div>}>
                        <DynamicContent />

                        <AnalysisResultThresholdInfo />
                        <AnalyisInfo myRef={analysisInfoRef}/>
                        <OpportunitiesAndLimits myRef={opportunitiesAndLimitsRef} />
                        <Design myRef={designRef} />
                        <BuildingsInfo myRef={buildingsRef}/>
                        <AnalysisResultProvider keyInfoRef={keyInfoRef} />
                        <Documents myRef={documentsRef}/>
                    </Suspense>
                </div> 
            : <SearchInfo />}
        </div>
    )
}

import { atom } from "recoil";

export interface SelectedStreetAddress {
  matrikkelId: string;
  latLng: LatLng;
}

export interface SuggestedStreetAddress {
  id: string;
  matrikkelId: string;
  text: string;
  latlng: LatLng;
}

export interface LatLng {
  lat: number;
  lng: number;
}

interface FritekstsokResponse {
  Id: string;
  Type: string;
  Text: string;
  Score: number;
  Url: string;
  PayLoad: PayLoad;
  LevenshteinScore: number;
  ExactMatches: number;
}

interface PayLoad {
  AdresseMatrikkelNummer?: string;
  MatrikkelNummer?: string;
  Text: string;
  AdresseId: string;
  VegAdresseId: string;
  Posisjon: {
    X: number;
    Y: number;
    SRS: number;
  };
  PostNummer: string;
  PostSted: string;
}

export const userSelectedStreetAddress = atom<SelectedStreetAddress | null>({
  key: "userSelectedStreetAddress",
  default: null,
});

const suggestUrl = process.env.REACT_APP_GATE_ADRESSE_SOK_URL;
const apiKey = process.env.REACT_APP_API_KEY;

export async function fritekstsok(
  searchText: string
): Promise<SuggestedStreetAddress[]> {
  if (searchText !== null && searchText !== "") {
    const query = `${suggestUrl}?Query=${searchText}&Size=5&Targets=gateadresse,matrikkelenhet&KommuneContext=3042,3043&KommuneLimit=3042,3043`;

    const apiResult = await fetch(query, {
      headers: {
        Accept: "application/json",
        "X-WAAPI-TOKEN": `${apiKey}`,
      },
      method: "GET",
    });

    if (apiResult.status === 200) {
      return await fritekstsokResultToAdress(apiResult);
    }
  }

  return [];
}

const fritekstsokResultToAdress = async (
  apiResult: Response
): Promise<SuggestedStreetAddress[]> => {
  const suggestions = new Array<SuggestedStreetAddress>();

  const json = await apiResult.json();
  json.Options.forEach((suggestion: FritekstsokResponse) => {

    if (
      suggestion?.PayLoad?.Posisjon?.X &&
      suggestion?.PayLoad?.Posisjon?.Y &&
      suggestion?.PayLoad?.AdresseMatrikkelNummer
    ) {
      suggestions.push({
        id: suggestion.Id,
        matrikkelId: suggestion.PayLoad.AdresseMatrikkelNummer,
        text: suggestion.Text,
        latlng: {
          lat: suggestion.PayLoad.Posisjon.X,
          lng: suggestion.PayLoad.Posisjon.Y,
        },
      });
    } else if (
      suggestion?.PayLoad?.Posisjon?.X &&
      suggestion?.PayLoad?.Posisjon?.Y &&
      suggestion?.PayLoad?.MatrikkelNummer
    ) {
      suggestions.push({
        id: suggestion.Id,
        matrikkelId: suggestion.PayLoad.MatrikkelNummer,
        text: suggestion.Text,
        latlng: {
          lat: suggestion.PayLoad.Posisjon.X,
          lng: suggestion.PayLoad.Posisjon.Y,
        },
      });
    }
  });

  return suggestions ?? [];
};

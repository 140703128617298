import { makeStyles, Paper, Typography } from "@material-ui/core";
import { FeatureCollection } from "geojson";
import { useEffect, useState } from "react";
import { useRecoilValue } from "recoil"
import { Formaal, mappedFeatureCollection } from "../../state/plan"
import { AnalysisResultCard } from "./AnalysisResultCard"
import { RoofInfo } from "../staticinfo/RoofInfo";
import { SectionHeader } from "../header/SectionHeader";
import { ByaInfo } from "../staticinfo/ByaInfo";

interface Props {
    keyInfoRef: React.MutableRefObject<any>;
}

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        display: "flex",
        flexDirection: "column",
        marginBottom: theme.spacing(2),
        padding: theme.spacing(2),

        [theme.breakpoints.down('md')]: {
            paddingLeft: 0,
            paddingRight: 0
        },
    },
    headerBox: {
        textAlign: 'center',
        marginBottom: theme.spacing(4)
    },

}));

export const AnalysisResultProvider = ({ keyInfoRef }: Props) => {
    const formaal = useRecoilValue<FeatureCollection[] | null>(mappedFeatureCollection);
    const [filteredFormaal, setFilteredFormaal] = useState<FeatureCollection[] | null>(null);
    const classes = useStyles();

    useEffect(() => {
        if (formaal) {
            removeFirstFc(formaal);
        }
    }, [formaal])

    //We know that the first item in the array do not contain formaal information, so we remove it before writing out formålsflater
    const removeFirstFc = (fcList: FeatureCollection[]) => {
        const fc = fcList.filter((fc) => !fc.features.find((feature) => feature?.properties?.key === "Omriss"))
        setFilteredFormaal(fc);
    }

    return (
        <div>
            {filteredFormaal && filteredFormaal?.map((fc, index) => {
                return <div key={index} >
                    
                    <Paper className={classes.root} ref={keyInfoRef}>
                        <>
                            <SectionHeader
                                title='Nøkkelinformasjon'
                            />
                            <Typography variant='subtitle1' align='center'>
                            Dette området er regulert til: <span style={{fontWeight: 'bold'}}>{fc.features[fc.features.length -1]?.properties?.tittel}</span>.  Muligheter og begrensninger for området finner du i tabellene med nøkkelinformasjon. Gjeldende areal for eiendomsflaten finner du i kartet.
                            </Typography>
                            {fc.features.map((currentFormaal) => {
                                //Special case for hoyder
                                if (currentFormaal?.properties?.formaal && currentFormaal?.properties?.key === "Hoyder") {
                                    return <div key={currentFormaal?.properties?.formaal?.Tittel + currentFormaal.properties?.index}>
                                        <AnalysisResultCard index={currentFormaal.properties?.index} formaal={currentFormaal?.properties?.formaal as Formaal} >
                                            <RoofInfo />
                                        </AnalysisResultCard>
                                    </div>
                                }
                                //Special case for storrelser og ledig areal
                                if (currentFormaal?.properties?.formaal && currentFormaal?.properties?.key === "StorrelserOgArealer") {
                                    return <div key={currentFormaal?.properties?.formaal?.Tittel + currentFormaal.properties?.index}>
                                        <AnalysisResultCard index={currentFormaal.properties?.index} formaal={currentFormaal?.properties?.formaal as Formaal} >
                                            <ByaInfo />
                                        </AnalysisResultCard>
                                    </div>
                                }
                                if (currentFormaal?.properties?.formaal) {
                                    return <div key={currentFormaal?.properties?.formaal?.Tittel + currentFormaal.properties?.index}>
                                        <AnalysisResultCard index={currentFormaal.properties?.index} formaal={currentFormaal?.properties?.formaal as Formaal} >
                                        </AnalysisResultCard>
                                    </div>
                                }
                            })}
                        </>
                    </Paper>

                </div>
            })}
        </div>
    )
}

import { makeStyles, Paper } from "@material-ui/core";
import { useRecoilValue } from "recoil";
import { isHytteplan } from "../../state/plan";
import { SectionHeader } from "../header/SectionHeader";
import { HowTo } from "./HowTo";


interface Props {
    myRef: React.MutableRefObject<any>;
}

const useStyles = makeStyles((theme) => ({
    paper: {
        marginBottom: theme.spacing(2),
        padding: theme.spacing(2),
    }
}));

export const Design = ({myRef}: Props) => {
    const classes = useStyles();
    const hyttePlan = useRecoilValue<boolean>(isHytteplan);

    return (
        <Paper className={classes.paper} ref={myRef} >
            <SectionHeader           
                title='Formål med reguleringsplanen'
            />

            {hyttePlan 
                ? <HowTo 
                    useDropdown={false}
                    opendropdown={false}
                    setOpenOverride={() => {null}}
                    image={null} 
                    title="Les mer" 
                    desc={[
                        {header: "Fritidsbolig", description: "Å bygge en fritidsbolig med tilhørende bygninger og anlegg krever inngrep i terrenget. Særlig synlig vil terrenginngrep være i områder som i hovedsak er uberørt natur. Større inngrep i form av oppfylling, utgraving, sprenging, planering og lignende kan i stor grad endre området sin karakter. Bestemmelsene som er vedtatt for området og tomta har som mål å begrense skjemmende inngrep."},
                        ]} 
                    bulletpoints={null}
                    readmore={null}    
                        />
                : <HowTo 
                    useDropdown={false}
                    opendropdown={false}
                    setOpenOverride={() => {null}}
                    image={null} 
                    title="Les mer" 
                    desc={[
                        {header: "Boliger", description: "Utforming av bygningene har mye å si for hvordan bygningsmassen på en tomt påvirker de nære omgivelsene, naboer, terreng og vegetasjon. I tillegg har det totale avtrykket av byggverka, dvs bygninger og andre konstruksjoner og anlegg, innvirkning på fjernvirkningen og sammenhengen med landskapet. Bestemmelsene som er vedtatt for området og tomta har som mål at bygningene skal tilpasses terreng, landskap og omgivelser. "},
                        ]} 
                    bulletpoints={null}
                    readmore={null}    
                    />
            }
        </Paper>
    )
}

import { makeStyles, Paper } from "@material-ui/core";
import { SectionHeader } from "../header/SectionHeader";
import { HowTo } from "./HowTo";


interface Props {
    myRef: React.MutableRefObject<any>;
}

const useStyles = makeStyles((theme) => ({
    paper: {
        marginBottom: theme.spacing(2),
        padding: theme.spacing(2),
    }
}));

export const AnalyisInfo = ({myRef}: Props) => {
    const classes = useStyles();

    return (
        <Paper className={classes.paper} ref={myRef} >
            <SectionHeader           
                title='Informasjon'
            />
            <HowTo 
                    useDropdown={false}
                    opendropdown={false}
                    setOpenOverride={() => {null}}
                    image={null} 
                    title="AnalyseInformasjon" 
                    desc={[
                        {header: "", description: "Under vises nøkkelinformasjon om valgt eiendom. Dette gjelder informasjon om hvor stor del av eiendommen du har lov til å bygge ut, hvor høyt du kan bygge, regler for boder og anneks, garasjer, parkeringsplasser og andre forhold. Informasjonen vises per eiendomsflate innenfor valgt eiendom. En eiendomsflate forteller deg hva et gitt areal skal brukes til. Eiendomsflatene for din eiendom finner du også i kartet. "},
                        ]} 
                    bulletpoints={null}
                    readmore={null}    
                        />
        </Paper>
    )
}

import { RecoilRoot } from "recoil";
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import bluegrey from '@material-ui/core/colors/blueGrey';
import { MainWrapper } from "./components/MainWrapper";


function App() {
  const theme = createMuiTheme({
    palette: {
      primary: {
        main: '#0082BE',
      },
      secondary:{
        main: bluegrey[600],
      }

    },
  
  });

  return (
    <RecoilRoot>
      <ThemeProvider theme={theme}>
        <MainWrapper />
      </ThemeProvider>
    </RecoilRoot>
  );
}
export default App;

import { makeStyles } from "@material-ui/core";

export const useCommonStyles = makeStyles((theme) => ({
    centeredContentParent: {
        display: 'flex',
        justifyContent: 'center',
        width: "100%",
        marginBottom: theme.spacing(4)
    },
    subtitle: {
        maxWidth: '100%'
    }
}));
import { atom } from "recoil";

export const buildingsOpenState = atom<boolean>({
    key: "buildingsOpenState",
    default: false
})

export const designOpenState = atom<boolean>({
    key: "designOpenState",
    default: false
})

export const helpMeOpenState = atom<boolean>({
    key: "helpMeOpenState",
    default: false
})


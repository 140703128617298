import { Divider } from '@material-ui/core';
import { makeStyles} from '@material-ui/core'
import { ProperyInfo } from '../property/PropertyInfo';
import { MapToggleButtons } from '../planmap/MapToggleButtons';
import { PlanMap } from '../planmap/PlanMap';


const useStyles = makeStyles((theme) =>({
    root: {
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'row',
        [theme.breakpoints.down('md')]: {
            flexDirection: 'column',
          },
    },
    content: {
        width: '40%',
        [theme.breakpoints.down('md')]: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            width: '100%',
          },
    }
  }));

export const DynamicContent = () => {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <PlanMap />
            <div className={classes.content}>
                <MapToggleButtons />
                <Divider />
                <ProperyInfo />
            </div>
        </div>
    )
}



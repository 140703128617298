import { useEffect, useMemo, useState } from 'react'
import Autocomplete from '@material-ui/lab/Autocomplete';
import { InputAdornment, TextField, Theme, withStyles } from '@material-ui/core';
import { useSetRecoilState } from 'recoil';
import { SuggestedStreetAddress, SelectedStreetAddress, userSelectedStreetAddress, fritekstsok } from '../../state/fritekstsoek';
import SearchIcon from '@material-ui/icons/Search';
import { useCommonStyles } from '../../style/common';
import { addressSuggestions } from '../../data/predefined';
import { debounce } from 'lodash';

const CustomTextField = withStyles((theme: Theme) => ({
    root: {
        '& .MuiInputBase-root': {
            paddingRight: `${theme.spacing(2)}px !important`,
        },
    },
}))(TextField);

interface Props {
    mapRef?: React.MutableRefObject<any>;
}

export const Search = ({ mapRef }: Props) => {
    const classes = useCommonStyles();
    const [inputValue, setInputValue] = useState('');
    const [options, setOptions] = useState<SuggestedStreetAddress[]>(addressSuggestions())
    const [selectedOption, setSelectedOption] = useState<SuggestedStreetAddress | null>(null);

    const setSelectedArea = useSetRecoilState<SelectedStreetAddress | null>(userSelectedStreetAddress);


    useEffect(() => {
        if (selectedOption) {
            const { matrikkelId, latlng } = selectedOption;
            setSelectedArea({ matrikkelId: matrikkelId, latLng: latlng })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedOption])


    const fetchFromFritekstsok = useMemo(
        () =>
        debounce(async (input: string, callback: (results: SuggestedStreetAddress[]) => void) => {
                if(input){
                    const results = await fritekstsok(input)
                    callback(results)
                }             
            }, 150),
        [],
    );

    useEffect(() => {
        let active = true;

        if (inputValue === '') {
            setOptions(addressSuggestions());
        }

        fetchFromFritekstsok(inputValue, (results: SuggestedStreetAddress[]) => {
            if (active) {

                let newOptions = [] as SuggestedStreetAddress[];

                if (selectedOption) {
                    newOptions = [selectedOption];
                }

                if (results) {
                    newOptions = [...newOptions, ...results];
                }

                setOptions(newOptions);
            }
        });


        return () => {
            active = false;
        };

    }, [selectedOption, inputValue, fetchFromFritekstsok]);



    return (
        <div ref={mapRef} className={classes.centeredContentParent}>
            <Autocomplete
                id="fritekstsok-demo"
                options={options}
                value={selectedOption}
                getOptionLabel={(s: SuggestedStreetAddress) => s.text}
                filterOptions={(x) => x}
                style={{
                    maxWidth: '80vw',
                    width: 400,
                    backgroundColor: 'white'
                }}
                autoComplete
                includeInputInList
                filterSelectedOptions
                onChange={(_, newValue: SuggestedStreetAddress | null) => {
                    setOptions(newValue ? [newValue, ...options] : options);
                    setSelectedOption(newValue);
                }}
                onInputChange={(_, newInputValue) => {
                    setInputValue(newInputValue);
                }}

                renderInput={(params: any) =>
                    <CustomTextField
                        {...params}
                        id="standard-basic"
                        color='secondary'
                        label="Søk opp adresse"
                        variant="outlined"
                        placeholder="Svøovegen 120"
                        size="small"
                        InputProps={{
                            ...params.InputProps,
                            endAdornment:
                                <InputAdornment position="end">
                                    <SearchIcon color='primary' />
                                </InputAdornment>
                        }}
                    />}
            />

        </div>

    )
}


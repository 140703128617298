import React from "react";
import { makeStyles } from "@material-ui/core";
import { HowTo } from "../staticinfo/HowTo";

interface Props {

}


const useStyles = makeStyles((theme) => ({
    buttons: {
        padding: theme.spacing(1)
    }
  }));

export const ByaInfo = (props: Props) => {
    const classes = useStyles();    
    return (
        <div className={classes.buttons}>
            <HowTo 
                useDropdown={true}
                opendropdown={false}
                setOpenOverride={() => {null}}
                image="/Bebygdarealforentomt.PNG" 
                title="Hvordan beregnes bebygd areal(BYA)?" 
                desc={[
                    {header: "Bebygd areal for en tomt", description: "Bebygd areal (BYA) for en tomt er summen av bebygd areal for alle bygninger, bygningsdeler og konstruksjoner over bakken, åpent overbygd areal og nødvendig areal for biloppstillingsplasser på tomten. Bebygd areal på en tomt skrives m2-BYA og angis i hele tall"},
                    ]} 
                bulletpoints={null}
                readmore={"https://dibk.no/regelverk/byggteknisk-forskrift-tek17/5/5-2/"}    
                    />
            <div style={{marginBottom: 3}}></div>
            <HowTo 
                useDropdown={true}
                opendropdown={false}
                setOpenOverride={() => {null}}
                image="/formelforbya.PNG" 
                title="Hva menes med %-BYA?" 
                desc={[
                    {header: "Hva menes med %-BYA?", description: "Prosent av bebygd areal på en tomt forkortes %-BYA. Det brukes til å angi den prosentvise størrelsen en bygning utgjør på en tomt og hvor stor del av tomten som er ubebygd. I mange kommunale planbestemmelser er det en øvre grense for hvor stort man kan bygge på en tomt. Dette er ofte oppgitt i %-BYA, for eksempel maksimalt 20 %-BYA."},
                    {header: "Hvordan beregnes %-BYA?", description: "%-BYA beregnes som vist på bildet under."},
                        
                ]} 
                bulletpoints={null}
                readmore={"https://dibk.no/regelverk/byggteknisk-forskrift-tek17/5/5-3/"}  
                />
        </div>
    )
}

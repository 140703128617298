import React from "react";
import { makeStyles, Table, TableBody, TableCell, TableRow, Typography } from "@material-ui/core";
import { Formaal } from "../../state/plan"
import { ExplanationIcon } from "../common/ExplanationIcon";

interface Props {
    index: number;
    formaal: Formaal;
    children: React.ReactNode | null;
}

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        marginBottom: theme.spacing(4),
    },
    header: {
        textAlign: 'left',
        margin: theme.spacing(2),
        padding: theme.spacing(1),
    },
    mainbox: {
        backgroundColor: '#e8e8e8',
        margin: theme.spacing(2),
        padding: theme.spacing(1),
        borderRadius: 5,
    },
    propertybox: {
        backgroundColor: 'white',
    },
    keyvalue: {
        display: "flex",
        flexDirection: "row"
    }
}));

export const AnalysisResultCard = ({ formaal, index, children }: Props) => {
    const classes = useStyles();

    return (
        <div className={classes.root}>


            <Typography className={classes.header} align='left' variant='h6'>
                {formaal.Tittel}
            </Typography>

            <div className={classes.mainbox}>
                <Typography variant="subtitle2" style={{ padding: 4, marginBottom: 10 }}>{formaal.Beskrivelse}</Typography>

                <div className={classes.propertybox}>
                    <Table size="small">
                        <TableBody>
                            {formaal.Egenskaper && formaal.Egenskaper.map((propery, i) => {
                                
                                const explanation = propery.Explanation;
                                if (propery.Key && propery.Value) {
                                    return <TableRow key={i}>
                                        <TableCell style={{ verticalAlign: 'top' }}>{propery.Key}</TableCell>
                                        <TableCell align="left" style={{ fontWeight: 'bold' }}>{` ${propery.Value}`}</TableCell>
                                        <TableCell align="left" >{explanation && explanation.length > 1 && <ExplanationIcon text={explanation}/>}</TableCell>
                                    </TableRow>
                                }
                                return null;
                            })}
                        </TableBody>
                    </Table>
                    {children}
                </div>
            </div>
        </div>
    )
}
import { makeStyles, Paper } from "@material-ui/core";
import { useRecoilState } from "recoil";
import { buildingsOpenState } from "../../state/static";
import { SectionHeader } from "../header/SectionHeader";
import { HowTo } from "./HowTo";


interface Props {
    myRef: React.MutableRefObject<any>;
}

const useStyles = makeStyles((theme) => ({
    paper: {
        marginBottom: theme.spacing(2),
        padding: theme.spacing(2),
    }
}));

export const BuildingsInfo = ({myRef}: Props) => {
    const classes = useStyles();
    const [open, setOpen] = useRecoilState<boolean>(buildingsOpenState);
    
    return (
        <Paper className={classes.paper} ref={myRef} >
            <SectionHeader           
                title='Bygninger og andre tiltak på eiendommen'
            />
          <HowTo 
                    useDropdown={true}
                    opendropdown={open}
                    setOpenOverride={setOpen}
                    image={null} 
                    title="Les mer" 
                    desc={[
                        {header: "Søknadspliktige tiltak", description: "Bygningene som er listet opp under er alltid søknadspliktige. I tillegg kan det komme fram av planbestemmelsene om der er andre byggverk det må søkes om.  "},
                        {header: "Boenhet", description: "Dette er hovedbygningen på tomta. Om ikke annet er nevnt spesielt er det lov å sette opp en boenhet pr tomt. En boenhet er definert som et bygg som har alle hovedfunksjoner (stue, kjøkken, soverom, bad og toalett) og som skal brukes til bolig/fritidsbolig. "},
                        {header: "Anneks", description: "Et anneks er en frittstående bygning ment for opphold. Bygget er ikke en selvstendig enhet og kan derfor ikke inneholde kjøkken. "},
                        {header: "Uthus/Garasje/Bod", description: "Frittstående uthus, garasje eller bod. Planbestemmelsene kan si noe om hvor mange og hvor store bygg som kan settes opp. "},
                        {header: "Ikke søknadspliktige tiltak ", description: "Det er mye en kan bygge eller sette opp på egen tomt og i eget hus uten at en behøver å søke om tillatelse til det. Men selv om du ikke trenger å søke om byggetillatelse, må du overholde forbud og krav i planbestemmelsene for området, og det som står i loven og forskriftene. For mer info, se nøkkelinformasjon. "},
                          
                    ]} 
                    bulletpoints={null}
                    readmore={"https://dibk.no/bygge-selv/dette-kan-du-bygge-uten-a-soke"}    
                        />
        </Paper>
    )
}

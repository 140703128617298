import { Button, makeStyles, Paper, Typography } from '@material-ui/core'
import { openInNewTab } from '../../utils/utils';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%'
    },
    paper: {
        display: 'flex',
        flexDirection: "column",
        padding: theme.spacing(2),
        marginBottom: theme.spacing(2),
        alignItems: 'center',
        width: '100%',
        maxWidth: 1000,
    },

}));

export const Questionnaire = () => {
    const classes = useStyles();
    const questionnaire_url = 'https://mindshift.research.net/r/HK3HGCS';

    return (
        <div className={classes.root}>
            <Typography variant='h6'>
                Hva synes du?
            </Typography>

            <Typography paragraph>
                Siden dette er en pilot vil vi gjerne høre hva du synes. Hjelp oss gjerne ved å svare på noen FÅ spørsmål?
            </Typography>

            <Button variant='outlined' color='primary'
            onClick={() => openInNewTab(questionnaire_url)}>
                Start undersøkelse
            </Button>
        </div>
    )
}



import { AppBar, CssBaseline, Divider, Hidden, ListItemText, Toolbar, Typography } from '@material-ui/core';
import { ListItem } from '@material-ui/core';
import { Button, createStyles, Drawer, List, makeStyles, Theme } from '@material-ui/core'
import { useCallback, useEffect, useState } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { Eiendom, selectedEiendom } from '../../state/plan';
import clsx from 'clsx';
import { throttle } from 'lodash';
import { buildingsOpenState } from '../../state/static';


const drawerWidth = 270;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
    },
    drawer: {
      [theme.breakpoints.up('sm')]: {
        width: drawerWidth,
        flexShrink: 0,
      },
    },
    appBar: {
      [theme.breakpoints.up('sm')]: {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: drawerWidth,
      },
    },
    menuButton: {
      marginRight: theme.spacing(2),
      fontWeight: 'bold',
      color: theme.palette.common.white,
      [theme.breakpoints.up('sm')]: {
        display: 'none',
      },
    },
    drawerPaper: {
      width: drawerWidth,
    },
    drawerText: {
      marginLeft: theme.spacing(1)
    },
    toolbar: theme.mixins.toolbar,
    button: {
      width: '100%'
    },
    main: {
      [theme.breakpoints.up('sm')]: {
        marginLeft: drawerWidth,
      },
    }
  }),
);

interface Props {
  analysisInfoRef: React.MutableRefObject<any>;
  opportunitiesAndLimitsRef: React.MutableRefObject<any>;
  designRef: React.MutableRefObject<any>;
  buildingsInfoRef: React.MutableRefObject<any>;
  keyInfoRef: React.MutableRefObject<any>;
  documentsRef: React.MutableRefObject<any>;
  mapRef: React.MutableRefObject<any>;
  children: React.ReactNode | null;
}

export const Content = ({
  analysisInfoRef,
  opportunitiesAndLimitsRef,
  designRef,
  buildingsInfoRef,
  keyInfoRef,
  documentsRef,
  mapRef,
  children
}: Props) => {

  const classes = useStyles();
  const setBuildingsOpen = useSetRecoilState<boolean>(buildingsOpenState);
  
  const eiendom = useRecoilValue<Eiendom | 'nohit' | null>(selectedEiendom);

  const [open, setOpen] = useState<boolean>(false);
  const [analyisInfoVisible, setAnalysisInfoVisible] = useState(false)
  const [muligheterVisible, setmuligheterVisible] = useState(false)
  const [tomtaVisible, setTomtaVisible] = useState(false)
  const [buildingsInfoVisible, setBuildingsInfoVisible] = useState(false)
  const [nokkelInfoVisible, setnokkelInfoVisible] = useState(false)
  const [documentsVisible, setdocumentsVisible] = useState(false)
  const [mapVisible, setmapVisible] = useState(false)

  const drawerToggle = () => {
    setOpen(!open);
  };


  const scrollToRef = (ref: React.MutableRefObject<any>, callback?: () => void ) => {
    ref.current?.scrollIntoView({ behavior: 'smooth' });
    callback && callback()
  }

  const SetElementVisibility = (ref: React.MutableRefObject<any>, setVisibility: React.Dispatch<React.SetStateAction<boolean>>) => {
    if (!ref.current) {
      setVisibility(false)
      return
    }
    const top = ref.current.getBoundingClientRect().top
    const bottom = ref.current.getBoundingClientRect().bottom

    const tolerance = 0;

    setVisibility((
      (top >= tolerance && top <= window.innerHeight)
      || (top < tolerance && bottom > window.innerHeight)))
  }


  const onScroll = useCallback(
    () => {
      SetElementVisibility(mapRef, setmapVisible)
      SetElementVisibility(analysisInfoRef, setAnalysisInfoVisible)
      SetElementVisibility(opportunitiesAndLimitsRef, setmuligheterVisible)
      SetElementVisibility(designRef, setTomtaVisible)
      SetElementVisibility(buildingsInfoRef, setBuildingsInfoVisible)
      SetElementVisibility(keyInfoRef, setnokkelInfoVisible)
      SetElementVisibility(documentsRef, setdocumentsVisible)
    },
    [documentsRef, mapRef, opportunitiesAndLimitsRef, designRef, keyInfoRef, analysisInfoRef, buildingsInfoRef],
  )

  useEffect(() => {
    document.addEventListener('scroll', onScroll, true)
    onScroll()
    return () => document.removeEventListener('scroll', throttle(onScroll, 150), true)
  }, [onScroll])


  const drawer = (
    <div>
      <Toolbar>
        <Typography variant='h5'>
          Innholdsfortegnelse
        </Typography>
      </Toolbar>
      <Divider />
      <List>

        <ListItem selected={mapVisible} button onClick={() => { scrollToRef(mapRef); }}>
          <ListItemText primary={"Kart"} />
        </ListItem>

        <ListItem selected={analyisInfoVisible && !mapVisible} button onClick={() => { scrollToRef(analysisInfoRef); }}>
          <ListItemText primary={"Informasjon"} />
        </ListItem>

        <ListItem selected={muligheterVisible && !(mapVisible || analyisInfoVisible)} button onClick={() => { scrollToRef(opportunitiesAndLimitsRef); }}>
          <ListItemText primary={"Muligheter og begrensninger"} />
        </ListItem>

        <ListItem selected={tomtaVisible && !(mapVisible || muligheterVisible || analyisInfoVisible)} button onClick={() => { scrollToRef(designRef); }}>
          <ListItemText primary={"Formål med regulreingsplanen"} />
        </ListItem>

        <ListItem selected={buildingsInfoVisible && !(mapVisible || muligheterVisible || analyisInfoVisible || tomtaVisible)} button onClick={() => { scrollToRef(buildingsInfoRef, () => setBuildingsOpen(true)); }}>
          <ListItemText primary={"Bygninger og andre tiltak"} />
        </ListItem>

        <ListItem selected={nokkelInfoVisible && !(mapVisible || muligheterVisible || tomtaVisible || analyisInfoVisible || buildingsInfoVisible)} button onClick={() => { scrollToRef(keyInfoRef); }}>
          <ListItemText primary={"Nøkkelinformasjon"} />
        </ListItem>

        <ListItem selected={documentsVisible && !(mapVisible  || muligheterVisible || tomtaVisible || nokkelInfoVisible || analyisInfoVisible || buildingsInfoVisible)} button onClick={() => { scrollToRef(documentsRef); }}>
          <ListItemText primary={"Dokumenter og lenker"} />
        </ListItem>

      </List>
    </div>);

  const showDrawer = eiendom != null && eiendom != 'nohit';

  return (
    <div className={classes.root}>
      <CssBaseline />
      {showDrawer && <div>
        <AppBar position="fixed" className={classes.appBar} color="primary">
          <Button className={classes.menuButton} onClick={drawerToggle}> Åpne meny</Button>
        </AppBar>
        <Hidden smUp implementation="css">
          <Drawer
            className={classes.drawer}
            variant="temporary"
            open={open}
            onClose={drawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
            anchor="left"
          >
            {drawer}
          </Drawer>
        </Hidden>
        <Hidden xsDown implementation="css">
          <Drawer
            className={classes.drawer}
            variant="permanent"
            classes={{
              paper: classes.drawerPaper,
            }}
            anchor="left"
          >
            {drawer}
          </Drawer>
        </Hidden>
      </div>}
      <div className={clsx({ [classes.main]: showDrawer })} >
        {children}
      </div>
    </div>
  )
}
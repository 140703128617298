import { IconButton, makeStyles, Paper, Popover, Theme, Tooltip, Typography, withStyles } from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';

interface Props {
    text: string
}

const HtmlTooltip = withStyles((theme: Theme) => ({
    tooltip: {
        fontSize: theme.typography.pxToRem(12),
    },
}))(Tooltip);

export const ExplanationIcon = ({ text }: Props) => {
    return (
        <>
            <HtmlTooltip title={text}>
                <IconButton            >
                    <InfoIcon color='primary' />
                </IconButton>
            </HtmlTooltip>
        </>
    )
}

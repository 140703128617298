import { makeStyles, Typography } from '@material-ui/core';
import { CustomDivider } from '../common/CustomDivider';

const useStyles = makeStyles((theme) => ({
    root: {
        paddingTop: theme.spacing(3),
        display: 'flex',
        justifyContent: 'center',
        height: 80,
        width: '100%'
    },
    logosstart: {
        display: 'flex'
    },
    text:{
        marginTop: theme.spacing(2)
    },
    kommunelogo: {
        width: 50,
        marginRight: theme.spacing(2)
    }
}));

export const Logos = () => {
    const classes = useStyles();

    return (
        <div>
            <div className={classes.root}>
                <div className={classes.logosstart}>
                    <img className={classes.kommunelogo} src="/hemsedal_logo.png" alt="hemsedal_logo" />
                    <img className={classes.kommunelogo} src="/aal_logo.png" alt="hemsedal_logo" />
                </div>
            </div>
            <div className={classes.text}>
                <Typography align='center' variant='h3' >
                    Hemsedal og Ål kommune
                </Typography>
                <CustomDivider selectedWidth={200}/>
            </div>
        </div>
        
    )
}

import { makeStyles, Paper } from "@material-ui/core"
import { SectionHeader } from "../header/SectionHeader";
import { HowTo } from "../staticinfo/HowTo";

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: "column",
        padding: theme.spacing(2),
        alignItems: 'center',
        maxWidth: 1000,
    },
    info:{
        marginBottom: theme.spacing(2),
    }
}));

export const SearchInfo = () => {
    const classes = useStyles();
    return (
        <div style={{display:'flex', justifyContent:'center'}}>       
            <Paper className={classes.root}>
            <SectionHeader title='Hvordan komme i gang?'/>
            <HowTo 
                    useDropdown={false}
                    opendropdown={false}
                    setOpenOverride={() => null}
                    image={null} 
                    title="Les mer" 
                    desc={[
                        {header: "Hvordan komme i gang?", description: "Alle adresser i Hemsedal og Ål kommune er søkbare. Imidlertid inneholder denne piloten kun noen utvalgte planer, så man vil ikke kunne få treff på alle adresser. Planene tilknyttet denne piloten hører til veiene Svøovegen, Øvre Kolbotn og Bjødnastølen."},
                        {header: "Søke på gateadresse?", description: "Skriv inn addressen i søkefeltet. Noen eksempelsøk: Svøovegen 121A, Hemsedal; Øvre Kolbotn 1, Ål; Bjødnastølen 10, Ål"},
                        {header: "Søke på gårds og bruksnummer?", description: "Enkelte eiendommer i de utvalgte planene har ikke en tilknyttet adresse, og må søkes opp ved hjelp av gårds og bruksnummer. Du søker på formatet gårdsnummer/bruksnummer. Eksempel: 57/44 i Hemsedal"}
                    ]} 
                    bulletpoints={null}
                    readmore={null}    
            />    
            </Paper>
        </div>
    )
}


import { Feature, FeatureCollection } from "geojson";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const onMapClick = (e : any) => {
    //TODO: Enable new search on coords
  }

export const editFeatureCollection = (currentFeatures: FeatureCollection[], selectedFeature: Feature) => {

  const newFcArray = new Array<FeatureCollection>();

  currentFeatures.forEach((fc) => {
    const newFeatureArray = new Array<Feature>();
      fc?.features.forEach((feature : any) => {
        if(feature === selectedFeature && feature?.properties){
          newFeatureArray.push({
            "type": "Feature",
            "properties": {
              "index": feature.properties.index,  
              "key": `${feature.properties.key}`,
              "tittel": `${feature.properties.tittel}`,
              "Beskrivelse": `${feature.properties.Beskrivelse}`,
              "show_on_map": feature.properties.show_on_map ? false : true,
              "has_geometry" : feature.properties.has_geometry,
              "formaal": feature.properties.formaal,
            },
            "geometry": feature.geometry
          })
        } else{
          newFeatureArray.push(feature)
        }
      })
      newFcArray.push(generateFeatureCollectionFromFeatureArray(newFeatureArray))
  })
  return newFcArray;
}

export const generateFeatureCollectionFromFeatureArray = (featureList : Feature[]) : FeatureCollection => {
  return  {
    "type" : "FeatureCollection",
    features: featureList
  } 
}

export const geoJsonColorMapping = (feature : any) => {
  switch(feature.properties.key){
    case "Omriss":
      return {color: "#0000ff"};
    case "Byggeforbud":
      return {color: "#ff0000"};
    case "Planformaal":
      return {color: "#00ff00"};
    case "StorrelserOgArealer":
      return {color: "#ffff00"};
    case "Hoyder":
      return {color: "#ffa500"};
    case "Hensynssoner":
      return {color: "#964b00"};
    case "BoderOgAnneks":
      return {color: "#ff0000"};
    case "GarasjeOgParkering":
      return {color: "#ff0000"};
    case "EstetiskeKrav":
      return {color: "#ff0000"};
    case "AndreForhold":
      return {color: "#ff0000"};
    case "AnnenInfo":
      return {color: "#ff0000"};
    default:
      return {color: "#ff0000"};
}
} 
import { makeStyles, Paper } from "@material-ui/core";
import { useRecoilValue } from "recoil";
import { isHytteplan } from "../../state/plan";
import { SectionHeader } from "../header/SectionHeader";
import { HowTo } from "./HowTo";


interface Props {
    myRef: React.MutableRefObject<any>;
}

const useStyles = makeStyles((theme) => ({
    paper: {
        marginBottom: theme.spacing(2),
        padding: theme.spacing(2),
    }
}));

export const OpportunitiesAndLimits = ({myRef}: Props) => {
    const classes = useStyles();
    const hyttePlan = useRecoilValue<boolean>(isHytteplan);

    
    return (
        <Paper className={classes.paper} ref={myRef} >
            <SectionHeader           
                title='Muligheter og begrensninger'
            />

            {hyttePlan 
                ? <HowTo 
                    useDropdown={false}
                    opendropdown={false}
                    setOpenOverride={() => {null}}
                    image={null} 
                    title="Les mer" 
                    desc={[
                        {header: "Muligheter  ", description: "Planen sikrer attraktive tomter for fritidsboliger med gode solforhold, god utsikt, usjenert plassering og god adkomst. Mellom tomtene er det sikra naturlig terreng og vegetasjon. Det er også sikra stier og skiløyper i området for felles bruk. "},
                        {header: "Begrensninger", description: "Planen setter rammer for størrelse og antall bygninger som kan bygges på tomta. I tillegg er det krav til fargevalg og materialbruk på fasade og tak, og utforming av gjerder. Det er også rammer for hvor mye du kan endre av det naturlege terrenget på tomta. Det er for å sikre det naturlege preget i hytteområdet og at området skal ha god tilpassing til omgivelsene "},
                        ]} 
                    bulletpoints={null}
                    readmore={null}    
                        />
                : <HowTo 
                    useDropdown={false}
                    opendropdown={false}
                    setOpenOverride={() => {null}}
                    image={null} 
                    title="Les mer" 
                    desc={[
                        {header: "Muligheter  ", description: "Planen sikrer attraktive tomter for eneboliger og leiligheter. Her kan du bygge din bolig i et område med felles leke- og møteplasser og god teknisk infrastruktur."},
                        {header: "Begrensninger", description: "Planen setter rammer for størrelse og høyde på bygninger som kan bygges på tomta. Det kan være krav til form og farge på bygninger og utendørs lagring for å sikre godt nabofellesskap."},
                        ]} 
                    bulletpoints={null}
                    readmore={null}    
                    />
            }
        </Paper>
    )
}

import React from "react";
import { Button, Collapse, makeStyles, Typography } from "@material-ui/core";
import { useState } from "react";
import ArrowDropDownIcon from '@material-ui/icons/ExpandMore';
import ArrowDropUpIcon from '@material-ui/icons/ExpandLess';
import { useEffect } from "react";
import clsx from 'clsx';
import LinkIcon from '@material-ui/icons/Link';


interface Props {
    useDropdown: boolean,
    opendropdown: boolean,
    setOpenOverride: (open: boolean) => any | null,
    image: string | null,
    title: string,
    desc: Description[] | null,
    bulletpoints: BulletPoints[] | null,
    readmore: string | null,
}

interface Description {
    header: string,
    description: string
}

interface BulletPoints {
    header: string,
    description: string
}

const useStyles = makeStyles((theme) => ({
    root:{
        marginBottom: theme.spacing(2),
    },
    rootOpen: {
        border: 'solid',
        borderWidth: 'thin',
        borderRadius: 5,
        borderColor: theme.palette.secondary.main
    },
    text: {
        padding: theme.spacing(2)
    },
    button: {
        display:'flex',
        justifyContent: 'space-between',
        width: '100%',
        // fontWeight: 'bold',
        
    },
    takimage: {
        width: '100%',
        marginRight: theme.spacing(2)
    },
    bullettext: {
        display: 'flex',
        flexDirection: 'row',
        padding: theme.spacing(1),
    }
}));

//https://stackoverflow.com/a/63627688/13774540
const openInNewTab = (url: string) => {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
    if (newWindow) newWindow.opener = null;
};

export const HowTo = ({useDropdown, opendropdown, setOpenOverride, image, title, desc, bulletpoints, readmore }: Props) => {

    const classes = useStyles();
    const [open, setOpen] = useState<boolean>(opendropdown);

    useEffect(() => {
        setOpen(opendropdown);
    }, [opendropdown])

    useEffect(() => {
        if (setOpenOverride !== null && open === false) {
            setOpenOverride(false)
        }
        // eslint-disable-next-line
    }, [open])


    const createContent = () => {return <div>
        {desc && desc.map((item, index) => (
            <div key={item.header + index} className={classes.text}>
                <Typography variant="h6">
                    {item.header}
                </Typography>
                <Typography variant="subtitle1">
                    {item.description}
                </Typography>
            </div>)
        )}

        {bulletpoints && bulletpoints.map((item, index) => (
            <div key={item.header + index} className={classes.bullettext}>
                <Typography variant="subtitle1" style={{ fontWeight: 'bold', marginRight: 4 }}>
                    {item.header}
                </Typography>
                <Typography variant="subtitle1">
                    {item.description}
                </Typography>
            </div>)
        )}

        {image &&
            <img src={image} alt="bilde_av_tak" className={classes.takimage} />}

        {readmore &&
            <Button
                variant='contained'
                color='default'
                className={classes.button}
                onClick={() => openInNewTab(readmore)}
                endIcon={ <LinkIcon />}  
                >
                Les Mer
            </Button>
        }
    </div>}

    return (
        <div className={clsx({
            [classes.root]: true,
            [classes.rootOpen]: useDropdown,
          })}>
              {useDropdown && <Button
                variant="contained"
                color={open ? "secondary" : 'primary'}
                className={classes.button}
                startIcon={open ? <ArrowDropUpIcon /> :<ArrowDropDownIcon />  }
                endIcon={open ? <ArrowDropUpIcon /> :<ArrowDropDownIcon />  }
                onClick={() => setOpen(!open)}
            >
                {open ? "Lukk" : title}
            </Button> }
            
            { useDropdown 
                ? <Collapse in={open} >{createContent()}</Collapse>
                : createContent()
                }
        </div>
    )
}
import { BlobServiceClient } from '@azure/storage-blob';
import { Eiendom } from '../state/plan';

const account = process.env.REACT_APP_STORAGE_NAME
const container = process.env.REACT_APP_STORAGE_CONTAINER_NAME
const bestemmelsesContainer = process.env.REACT_APP_BESTEMMELSE_CONTAINER_NAME

export const getEiendom = async (selectedEiendom : string) : Promise<Eiendom | null> => {

    const blobServiceClient = new BlobServiceClient(`https://${account}.blob.core.windows.net`);
    const containerClient = blobServiceClient.getContainerClient(`${container}`);
    const blobClient = containerClient.getBlobClient(`${selectedEiendom}.json`);
    try{
        const downloadBlockBlobResponse = await blobClient.download();
        if(downloadBlockBlobResponse._response.status === 200){
            const downloaded = await blobToString(await downloadBlockBlobResponse.blobBody);
            const json = JSON.parse(`${downloaded}`);
            return json as Eiendom;   
        }
    }catch(e){
        return null;
    }
    return null;
}

export const getBestemmelsesUrl = (eiendom : Eiendom): string | null  => {
  const komm = eiendom.Eiendomsinformasjon.Mid.substring(0,4);
  const planid = eiendom.Eiendomsinformasjon.Planid;
  const containerUrl = `https://${account}.blob.core.windows.net/${bestemmelsesContainer}/`
  const fileName = `${komm}_${planid}.pdf`

  return `${containerUrl}${fileName}`

}

//From: https://docs.microsoft.com/en-us/javascript/api/overview/azure/storage-blob-readme?view=azure-node-latest
async function blobToString(blob: any) {
    const fileReader = new FileReader();
    return new Promise((resolve, reject) => {
      fileReader.onloadend = (ev: any) => {
        resolve(ev.target.result);
      };
      fileReader.onerror = reject;
      fileReader.readAsText(blob);
    });
  }

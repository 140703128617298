import { makeStyles, Typography } from '@material-ui/core';
import clsx from 'clsx';
import { useCommonStyles } from '../../style/common';
import { Questionnaire } from '../questionnaire/Questionnaire';
import { HowTo } from '../staticinfo/HowTo';

const useStyles = makeStyles((theme) => ({
    root: {
        paddingBottom: theme.spacing(4),
    },
    toptext:{
        [theme.breakpoints.up('md')]: {
            display: 'flex',
          },        
    },
    toptextitem:{
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
    },
    padBottom: {
        paddingBottom: theme.spacing(4),
    }
}));

export const Header = () => {
    const classes = useCommonStyles();
    const styles = useStyles();

    return (
        <div className={styles.root}>
            <div className={styles.toptext}>
                <div className={ clsx(classes.centeredContentParent, styles.toptextitem)}>
                    <Typography className={classes.subtitle} align='center' variant='h6' >
                        Denne nettsiden oppsummerer og gir en forenklet tolking av noen utvalgte reguleringsplaner i Hemsedal og Ål kommune. I disse planene kan du søke på bestemte eiendommer og få en oversiktlig framstilling av hvilke planbestemmelser som gjelder. Her vil du i tillegg finne dokumenter og kart som vil være relevante for deg.
                    </Typography>
                </div>
                <div className={clsx(styles.padBottom, styles.toptextitem)}>
                    <Questionnaire />
                </div>
            </div>
            <HowTo
                useDropdown={true}
                opendropdown={false}
                setOpenOverride={() => { null }}
                image={null}
                title="Hva er en reguleringsplan?"
                desc={[
                    { header: "Hva er en reguleringsplan? ", description: "En reguleringsplan er kommunen sitt verktøy for å styre utviklinga av et område. Planen kan for eksempel styre hvor du kan bygge, hvor veier skal gå og hvor det skal være uberørt natur. En reguleringsplan er en politisk vedtatt plan for et begrensa område. Planen inneholder kart og planbestemmelser. Den er juridisk bindende og forteller hvilket formål eiendommen har, f.eks bolig, fritidsbolig, friområde, veg osv. Den kan også fortelle hvor stort og høgt du kan bygge.  " },
                    { header: "", description: "I områder hvor det ikke er vedtatt egen reguleringsplan er det arealdelen av kommuneplanen sitt plankart og bestemmelser som gjelder. I noen områder er det eldre reguleringsplaner og da kan arealdelen av kommuneplanen ha erstatta den eldre planen. Det er alltid den nyeste planen som gjelder for et område. " }
                ]}
                bulletpoints={null}
                readmore={null}
            />
        </div>
    )
}

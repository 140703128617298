import { makeStyles, Paper, List, ListItem, ListItemText, ListItemIcon } from "@material-ui/core";
import { useRecoilValue } from "recoil";
import { bestemmelsesDocumentState, Eiendom, selectedEiendom } from "../../state/plan";
import FileIcon from '@material-ui/icons/InsertDriveFile';
import LinkIcon from '@material-ui/icons/Link';
import { openInNewTab } from "../../utils/utils";
import { SectionHeader } from "../header/SectionHeader";

const useStyles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
        width: '100%',
        marginBottom: theme.spacing(6),
    },
    header: {
        textAlign: 'center',
    },
    inline: {
        display: 'inline',
    },
}));

interface Props {
    myRef: React.MutableRefObject<any>;
}

export const Documents = ({myRef}: Props) => {
    const classes = useStyles();
    const bestemmelsesUrl = useRecoilValue(bestemmelsesDocumentState);
    const eiendom = useRecoilValue<Eiendom | 'nohit'| null>(selectedEiendom);

    const defaultListItem = (url: string, primaryText: string, secondaryText: string, icon: any) => {
        return <ListItem
            button
            onClick={() => openInNewTab(url)}
            alignItems="flex-start">
            <ListItemIcon>
                {icon}
            </ListItemIcon>
            <ListItemText
                primary={primaryText}
                secondary={secondaryText}
            />
        </ListItem>
    }
    return (
        <Paper className={classes.root} ref={myRef}>
            <SectionHeader
                title='Dokumenter og lenker'
            />
            <List>
                {bestemmelsesUrl && defaultListItem(bestemmelsesUrl, "Gjeldende Bestemmelser", "Dokumentet gjeldende bestemmelser inneholder de konkrete reglene som gjelder for dette området", <FileIcon />)}
                {defaultListItem("https://www.hemsedal.kommune.no/", "Hemsedal Kommune", "Åpne Hemsedal kommune sin hjemmeside", <LinkIcon />)}
                {defaultListItem("https://www.aal.kommune.no", "Ål Kommune", "Åpne Ål Kommune sin hjemmeside", <LinkIcon />)}
                {eiendom && eiendom != 'nohit' && eiendom.Eiendomsinformasjon.WmsUrl && defaultListItem(eiendom.Eiendomsinformasjon.WmsUrl, "Link til orginalt plankart", "Her vil du kunne åpne orginalplanen i kommunekart", <LinkIcon />)}
            </List>
        </Paper>
    )
}

import { useEffect, useState } from 'react';
import L from 'leaflet';
import { FeatureCollection } from 'geojson';
import { makeStyles } from '@material-ui/core';
import { useRecoilState, useRecoilValue} from 'recoil';
import { SelectedStreetAddress, userSelectedStreetAddress } from '../../state/fritekstsoek';
import { geoJsonColorMapping } from '../../utils/map';
import { InitMap, mapFeatures } from '../../state/map';
import { mappedFeatureCollection } from '../../state/plan';

interface Props {

}

const useStyles = makeStyles((theme) =>({
  root: {
    display: "flex", 
    flexDirection: "row", 
    flexWrap: "wrap", 
    width: '100%', 
  },
  map:{ 
    width: '100%', 
    height: "400px"
  }
}));

export const PlanMap = (props: Props) => {

    const [mapView, setMapView] = useState<L.Map | null>();
    const [currentMapLayers, setCurrentMapLayer] = useState<L.GeoJSON<any>[] | null>();
    const [currentMapFeatures, setCurrentMapFeatures] = useRecoilState<FeatureCollection[] | null>(mapFeatures);
    const selectedSearch = useRecoilValue<SelectedStreetAddress | null>(userSelectedStreetAddress)
    const featureCollectionArray = useRecoilValue<FeatureCollection[] | null>(mappedFeatureCollection)
    const classes = useStyles();

    //Init Map
    useEffect(() => {
      if(!mapView && selectedSearch){
        setTimeout(() => {
          setMapView(InitMap(selectedSearch));  
        }, 250);
      }else if(mapView){
        setTimeout(() => {
          mapView.invalidateSize();  
        }, 250);
      }
      }, [mapView, selectedSearch]);

    //Update by search change
    useEffect(() => {
      if(mapView && featureCollectionArray && selectedSearch){
        setCurrentMapFeatures(featureCollectionArray);   
      }  
      // eslint-disable-next-line
    }, [mapView, selectedSearch]);

    useEffect(() => {
      if (mapView && currentMapLayers){
        currentMapLayers.forEach((layer) => {
          mapView.removeLayer(layer);  
        })  
      }
      if (mapView && currentMapFeatures){
        let bounds: L.LatLngBounds | undefined;

        const layerList = new Array<L.GeoJSON<any>>();
        currentMapFeatures.forEach((fc) => {
          const layer = L.geoJSON(fc, {
            filter: geoJsonOnShowFilter,
            style: geoJsonColorMapping
          }).addTo(mapView);
          layerList.push(layer); 
          bounds = bounds ?? layer.getBounds()
          bounds.extend(layer.getBounds());
          
        })
        setCurrentMapLayer(layerList);
        bounds && bounds.isValid() && mapView.flyToBounds(bounds);
      }
      // eslint-disable-next-line
    }, [currentMapFeatures]);

    const geoJsonOnShowFilter = (geoJsonFeature : any) : boolean => {
      return geoJsonFeature.properties.show_on_map
    } 

    return (
      <div className={classes.root}>
          <div id='map' className={classes.map}></div>
      </div>
    )
}

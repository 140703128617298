import React from "react";
import { makeStyles } from "@material-ui/core";
import { HowTo } from "./HowTo";

const useStyles = makeStyles((theme) => ({
    buttons: {
        padding: theme.spacing(1)
    }
  }));

export const RoofInfo = () => {
    const classes = useStyles();    
    return (
        <div className={classes.buttons}>
            <HowTo 
                useDropdown={true}
                opendropdown={false}
                setOpenOverride={() => {null}}
                image="/hvordanhoyde.jpeg" 
                title="Hvordan måle høyde" 
                desc={[
                    {header: "Slik måler du takhøyde", description: "Dersom ikke annet er fastsatt i planbestemmelse, skal gesims- og mønehøyde måles fra ferdig planert terrengs gjennomsnittsnivå rundt bygningen. Gjennomsnittlig terrengnivå beregnes etter at eventuelle terrengarbeider er utført. "},
                    {header: "Beregning av planert terrengs gjennomsnittsnivå ", description: "For bygninger på tomter med lite eller jevnt fall, vil det normalt være tilstrekkelig å fastsette kotehøyden på planert terreng i hvert hjørne av bygningen og beregne gjennomsnittet. "},
                    {header: "Eksempel på beregning av gjennomsnittlig kotehøyde", description: "Gjennomsnittlig kotehøyde = (kote A + kote B + kote C + kote D) / 4 (Du summerer kotehøydene i hvert hushjørne og deler på fire. Kotehøydene finner du i detaljerte kart hos kommunen.) "}
                    ]} 
                bulletpoints={null}
                readmore={"https://dibk.no/regelverk/byggteknisk-forskrift-tek17/6/6-2/"}    
                    />
            <div style={{marginBottom: 3}}></div>
            <HowTo 
                useDropdown={true}
                opendropdown={false}
                setOpenOverride={() => {null}}
                image="/Fleretakformer.jpeg" 
                title="Ulike former for tak" 
                desc={null} 
                bulletpoints={[
                    {header:"a)", description:"Saltak"},
                    {header:"b)", description:"Pulttak"},
                    {header:"c)", description:"Tak med altan i skrå flate"},
                    {header:"d)", description:"Saltak med ark"},
                    {header:"e)", description:"Flatt tak med takoppbygg"},
                    {header:"f)", description:"Saltak med tverrfløy"},
                ]}
                readmore={null}  
                />
        </div>
    )
}

import { Divider, makeStyles } from "@material-ui/core"

interface Props {
    selectedWidth: number
}

const useStyles = makeStyles((theme) => ({
    divider: {
        backgroundColor: theme.palette.primary.main,
        height: 2,
        margin: theme.spacing(2),
        marginBottom: theme.spacing(3)
    },
    dividerParent: {
        display: 'flex',
        justifyContent: 'center'
    },
}));

export const CustomDivider = ({selectedWidth}: Props) => {
    const classes = useStyles();

    return (
        <div className={classes.dividerParent}>
            <Divider className={classes.divider} style={{width: selectedWidth}} color='primary' variant="middle" />
        </div>
    )
}
